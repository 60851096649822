<template>
	<b-card body-class="text-center">
		<b-card-text>
			Account
			<b-icon-pencil-square @click="onEditClick" class="float-right" />
		</b-card-text>

		<b-row align-h="center" class="mt-4">
			<b-card class="image-card" body-class="p-0">
				<b-img :src="storeImage" style="height: 100%" thumbnail fluid center />
			</b-card>
		</b-row>

		<b-card-text class="text-capitalize mt-2">{{ storeName }}</b-card-text>

		<div>
			<!-- URL LINK -->
			<a v-if="storeUrl" :href="storeUrl" target="_blank" class="mr-3">
				<u>{{ storeUrl }}</u>
			</a>

			<!-- Copy to Clipboard -->
			<b-button id="copyUrlToClipboard" @click="copyUrlToClipboard" variant="outline-primary" class="py-0 px-1 mr-3">
				<b-icon-clipboard />
			</b-button>

			<!-- Share Button -->
			<b-button v-if="webShareApiSupported" @click="shareViaWebShare" variant="outline-primary" class="py-0 px-1">
				<b-icon-share />
			</b-button>

			<!-- Pop-over button for "Copied" message -->
			<b-tooltip triggers="manual" :show.sync="showCopiedMessage" target="copyUrlToClipboard" placement="top">
				Copied
			</b-tooltip>
		</div>
	</b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			showCopiedMessage: false,
		};
	},
	computed: {
		...mapGetters({
			store: "merchant/store/getStore",
			appShortLogo: "getAppShortLogo",
		}),
		webShareApiSupported() {
			return navigator.share && this.storeUrl;
		},
		storeUrl() {
			if (this.store) {
				return this.$options.filters.customerUrl("/".concat(this.store.storeUrlName));
			}
			return null;
		},
		storeImage() {
			if (this.hasStoreImage) {
				return this.store.storeImage.blobUrl;
			}
			return this.appShortLogo;
		},
		hasStoreImage() {
			if (this.store && this.store.storeImage && this.store.storeImage.blobUrl) {
				return true;
			}
			return false;
		},
		storeName() {
			if (this.store) {
				if (this.store.storeName) {
					return this.store.storeName;
				}
				return this.store.storeUrlName;
			}
			return null;
		},
	},
	methods: {
		onEditClick() {
			// go to store form
			this.$router.push({ name: "StoreForm" });
		},
		copyUrlToClipboard() {
			this.$copyText(this.storeUrl).then(() => {
				this.showCopiedMessage = true;
				// set timer to remove msg
				setTimeout(() => (this.showCopiedMessage = false), 1200);
			});
		},
		shareViaWebShare() {
			if (this.webShareApiSupported) {
				navigator.share({
					title: this.storeName,
					text: `Hey! You can now order online from ${this.storeName} using the link :`,
					url: this.storeUrl,
				});
			}
		},
	},
};
</script>

<style scoped>
.image-card {
	height: 90px;
}
</style>
