<template>
	<b-modal @change="onChange" :visible="showModal" header-text-variant="primary" title="Change Password" hide-footer>
		<!-- Error Message -->
		<b-alert variant="danger" :show="getErrorState(errors, 'message') == false" dismissible fade>
			{{ getErrorMessage(errors, "message") }}
		</b-alert>

		<!-- FORM -->
		<form>
			<!-- OLD PASSWORD -->
			<form-password :invalid-feedback="getErrorMessage(errors, 'oldPassword')" :state="getErrorState(errors, 'oldPassword')" id="oldPassword" v-model="form.oldPassword" placeholder="Current Password" />

			<!-- OLD PASSWORD -->
			<form-password :invalid-feedback="getErrorMessage(errors, 'password')" :state="getErrorState(errors, 'password')" id="password" v-model="form.password" placeholder="New Password" />

			<b-button @click.prevent="onSubmit" variant="danger" block pill>
				Change Password
			</b-button>
		</form>
	</b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { changePassword } from "../../apis/user";
export default {
	props: ["value"],
	data() {
		return {
			form: {
				oldPassword: null,
				password: null,
			},
			errors: null,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
		}),
		showModal() {
			return this.value;
		},
	},
	methods: {
		onSubmit() {
			this.errors = null;
			changePassword(this.form, this.token)
				.then(() => {
					this.resetForm();
					this.onChange(false);
				})
				.catch(({ data }) => {
					this.errors = data.errors;
				});
		},
		resetForm() {
			this.form = {};
		},
		onChange(isVisible) {
			this.$emit("input", isVisible);
		},
	},
};
</script>
