<template>
  <div>
    <BasicStore />

    <!-- Store Config -->
    <b-button
      @click="storeConfigClickHandler"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Store Configuration
    </b-button>

    <!-- Set Address -->
    <b-button
      @click="onAddressClick"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Store Address
    </b-button>

    <!-- Delivery Settings -->
    <b-button
      @click="onDeliverySettingsClick"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Delivery Settings
    </b-button>

    <!-- Business Hours -->
    <b-button
      @click="onBusinessHoursClick"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Business Hours
    </b-button>

    <!-- Change Password Button -->
    <b-button
      @click="onChangePasswordClick"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Change Password
    </b-button>

    <!-- CHANGE PASSWORD MODAL -->
    <ChangePasswordForm v-model="displayChangePasswordModal" />
  </div>
</template>

<script>
import BasicStore from "../../../components/store/BasicStore";
import ChangePasswordForm from "../../../components/account/ChangePasswordForm";
export default {
  components: {
    BasicStore,
    ChangePasswordForm,
  },
  data() {
    return {
      displayChangePasswordModal: false,
    };
  },
  methods: {
    storeConfigClickHandler() {
      this.$router.push({ name: "MerchantStoreConfig" });
    },
    onAddressClick() {
      this.$router.push({ name: "StoreAddressView" });
    },
    onDeliverySettingsClick() {
      this.$router.push({ name: "DeliverySettings" });
    },
    onBusinessHoursClick() {
      this.$router.push({ name: "BusinessHoursPage" });
    },
    onChangePasswordClick() {
      this.displayChangePasswordModal = true;
    },
  },
};
</script>
